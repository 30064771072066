.switch {
    width: 25px;
    height: 60px;
    background: #ccc;
    position: relative;
    border: 2px solid #000;
    border-radius: 3px;
    cursor: default;
    margin-bottom: 4px;
  }
  
  .slider {
    width: 60%; /* Keep the slider skinnier */
    height: 45%; /* Reduce the height to add padding */
    background: #000;
    position: absolute;
    bottom: 5%; /* Add a bit of space from the bottom */
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease;
  }
  
  .switch.on .slider {
    bottom: 50%; /* Adjust the positioning for the on state */
  }
  