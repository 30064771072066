/* App.css */
:root{
  --paperBackground:#3326;
  --rootPaperBackground:#3320;
  --rootPaperWidth: 80%;
  --rootBackgroundColor: #3320;
  --parleiiGreen: #4f5024;
  --parleiiBlue: #4f46e5;
}

  /* This is what holds everything on the page, is invisible box */
  .root-container {
      min-height: 100vh;
      background-color: var(--rootBackgroundColor);
      color: white;
      display: flex;
      flex-direction: column;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;
    }

  /* this is the paper that holds the content, is sudo invisible and keeps the padding*/
  .root-paper {
    padding: 1rem;
    background-color: var(--rootPaperBackground) !important;
    color: black;
    width: var(--rootPaperWidth);
    margin: 0% 10% 0% 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  /* This is the paper that hold the connecting live view message */
  .connecting-live-view {
    padding: 3rem;
    background-color: var(--paperBackground) !important;
    color: black;
    border: 3px solid black;
    width: auto;
    margin: 20px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  /* this is the paper that holds the, please connect message */
  .waiting-for-connect {
    width: 980px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 20px auto;
    background-color: var(--paperBackground) !important;
    border: 3px solid black;
    color: black;
  }

  .typography-centered {
    margin: 2rem;
    text-align: center;
    /* Add more styles as needed */
  }