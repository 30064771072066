:root{
    --paperBackground:#3326;
    --rootPaperBackground:#3320;
    --parleiiGreen: #4f5024;
    --parleiiBlue: #4f46e5;
  }

  .main-paper {
    width: 100%;
    padding: 3rem;
    margin-top: 2rem;
    background-color: var(--paperBackground) !important;
    border: 3px solid black; /* Assuming black border; adjust as needed */
    position: relative;
    margin-bottom: 1rem;
  }

  .main-paper-title {
    width: 20%;
    padding: 0.5rem;
    background-color: black !important; /* Assuming black background; adjust as needed */
    color: white !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    left: 20px;
  }

  .box-for-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2; /* Adjust the unit if '2' refers to something else */
    margin-bottom: 2rem; /* Corresponds to mb: 2 */
    margin-top: 1rem; /* Corresponds to mt: 1 */
    justify-content: space-between;
  }

  .paper-for-buttons {
    padding: 2rem; /* Adjust the unit if necessary */
    background-color: var(--paperBackground) !important; /* Assuming you're using the variable for paperOnRoot */
    border: 3px solid #000;
    color: #fff;
    display: flex;
    width:fit-content;
    gap: 2 !important;
    justify-content: space-between !important;
    align-items: center;
    text-align: center;
    margin: auto;
    margin-top: 2; /* Corresponds to mt: 2 */

  }

  .paper-for-message {
    padding: 1rem; /* Adjust the unit if necessary */
    background-color: var(--paperBackground) !important; /* Assuming you're using the variable for paperOnRoot */
    border: 3px solid #000;
    color: #fff;
    display: flex;
    width:fit-content;
    align-items: center;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 2; /* Corresponds to mt: 2 */

  }

  












